import React, { useState } from 'react'
import s from './style.module.scss'
import Footer from '../components/Footer/Footer'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';

const Authorisation: React.FC = () => {
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.user) as { phone: string };
  // const oid = useSelector((state: RootState) => state.oid) as string;
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone || '');
  const [code, setCode] = useState<string>('');
  const [isSendCode, setIsSendCode] = useState<boolean>(false);

  const pattern = /\D/g;

  const CREATE_VISITOR = process.env.REACT_APP_CREATE_VISITOR as string
  const SMS_CODE: string = process.env.REACT_APP_SEND_SMS as string

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputNumbersValue = onPhoneInput(e)

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
      let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
      let formattedInputValue = firstSymbols + ' '
      if (inputNumbersValue.length > 1) {
        formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
      }
      setPhoneNumber(formattedInputValue)
    }

    function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
      return e.target.value.replace(pattern, '')
    }
  }

  const getIp = async (): Promise<string> => {
    try {
      const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  interface CreateVisitorResponse {
    data: {
      oid: string,
      credit_sum: number,
      credit_days: number,
      fingerprint: {
        ip: string,
        user_agent: string
      }
    },
    status: string
  }

  // Requests 
  const sendPhoneForAuthorisation = async (phone_number: string) => {
    try {
      const ip = await getIp()
      const useragent = navigator.userAgent
      await axios.post<CreateVisitorResponse>(CREATE_VISITOR, {
        credit_sum: 0,
        credit_days: 0,
        user_agent: useragent,
        ip: ip
      }).then(res => {
        if (res.data.data.oid) {
          const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
          localStorage.setItem('oid', res.data.data.oid)
          axios.post(SMS_CODE, {
            phone_number: `${formattedPhoneNumber}` ,
            visitor_oid: res.data.data.oid
          })
            .then(res => console.log('', res))
            .catch(err => console.log(err))
        }
      })
        .catch(err => {
          if (err.status === 500) {
            navigate('/contact-info')
          } else if (err.status === 404) {
            alert('Ошибка 404: не найдено')
          } else {
            alert('Неправильный код')
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await sendPhoneForAuthorisation(phoneNumber)
    } catch (error) {
      console.log(error)
    }
    setIsSendCode(true)
  }

  const VALIDATE_CODE: string = process.env.REACT_APP_VALIDATE_CODE as string
  const handleSendCode = async (e: React.FormEvent<HTMLFormElement>, phone_number: string) => {
    const formattedPhoneNumber = phone_number.replace(/\D+/g, '').replace(/^8/, '+7');
    e.preventDefault();
    try {
      await axios.post(VALIDATE_CODE, {
        phone_number: formattedPhoneNumber,
        code
      })
        .then(res => console.log(res))
        .catch(err => alert("Неверный код"))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={s.container + ' ' + s.authorisation}>
        <div className={s.title}>
          <h1>ВХОД В ЛИЧНЫЙ КАБИЕТ</h1>
        </div>
        <div className={s.content}>
          <form method='post' onSubmit={e => handleGetCode(e)}>
            <div className={s.input_field}>
              <label htmlFor="login">Мобильный телефон</label>
              <input
                type="tel"
                id="login"
                name="login"
                value={phoneNumber}
                onChange={e => handleChangePhone(e)}
                placeholder="+7 (___) ___ __ __"
              />
            </div>
            {
              !isSendCode &&
              <div className={s.form_btn}>
                <input disabled={phoneNumber.length < 18} type="submit" value="Получить код по SMS" />
              </div>
            }
          </form>
          {
            isSendCode &&
            <form method='post' onSubmit={e => handleSendCode(e, phoneNumber)} style={{ marginTop: '-8px' }}>
              <div className={s.input_field}>
                <label htmlFor="code">Код подтверждения</label>
                <input
                  type="number"
                  id="code"
                  name="code"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  placeholder="_ _ _ _"
                />
              </div>
              <div className={s.form_btn}>
                <input disabled={!code || code.length < 4 || code.length > 4}
                  type="submit"
                  value="Продолжить"
                  maxLength={4} />
              </div>
            </form>
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Authorisation