import React from 'react'
import s from './style.module.scss'
import Calculateing from '../../components/Calculateing/Calculateing'

const Header: React.FC = () => {
  return (
    <div className={s.header}>
      <div className={s.calculateing_container}>
        <Calculateing />
      </div>
    </div>
  )
}

export default Header