import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Nav from './components/Nav/Nav';
import ContactInfo from './containers/ContactInfo/ContactInfo';
import {
  Home,
  PropositionsPage,
  AboutServicePage,
  AddressDataPage,
  Authorisation,
  ContactUsPage,
  FeedPage,
  MainProfile,
  NotFoundPage,
  PassportDataPage,
  PersonalAccount,
  Reports,
  Dashboard,
  Statistics,
  AutoFunnels,
  AdminMain
} from './page';

function App() {
  const user = {
    role: 'admin'
  }

  const getDashboardComponent = (user: any) => {
    switch (user?.role) {
      case 'admin':
        return <AdminMain />
      case 'user':
        return <NotFoundPage />
      default:
        return <NotFoundPage />
    }
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="App">
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Authorisation />} />
            <Route path="/admin/*" element={getDashboardComponent(user)} >
              <Route path="*" element={<NotFoundPage />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="statistics" element={<Statistics />} />
              <Route path="auto-funnels" element={<AutoFunnels />} />
            </Route>
            <Route path="/personal-account/*" element={<MainProfile />}>
              <Route path="profile" element={<PersonalAccount />} />
              <Route path="reports" element={<Reports />} />
              <Route path="feed" element={<FeedPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            {/* <Route path='/docs/:docName' element={<Docname />} /> */}
            <Route path="/about-us" element={<AboutServicePage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/passport" element={<PassportDataPage />} />
            <Route path="/address" element={<AddressDataPage />} />
            <Route path="/propositions" element={<PropositionsPage />} />
            <Route path="/contact-information" element={<ContactInfo />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
