import React, { useEffect, useState } from 'react'
import s from '../../page/style.module.scss'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store';

const ContactInfo: React.FC = () => {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [patronymic, setPatronymic] = useState('')
    const [phone, setPhone] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [email, setEmail] = useState('')
    const navigate = useNavigate()

    const oid = useSelector((state: RootState) => state.oid)
    const dispatcher = useDispatch()

    const [know, setKnow] = useState(false)
    const [agreement, setAgreement] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const pattern = /\D/g;
    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputNumbersValue = onPhoneInput(e)

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === '9') inputNumbersValue = '+' + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === '8') ? '+7' : '+7'
            let formattedInputValue = firstSymbols + ' '
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
            setPhone(formattedInputValue)
        }

        function onPhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
            return e.target.value.replace(pattern, '')
        }
    }

    const handleBirthdateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBirthdate(e.target.value);
    };

    useEffect(() => {
        (!oid && !localStorage.getItem('oid')) && window.location.replace('/')
    }, [oid])

    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string

    const handleAddContactInfo = async () => {
        setIsLoading(true)
        const formattedPhoneNumber = phone.replace(/\D+/g, '').replace(/^8/, '+7');
        // const dateParts = birthdate.split('.');

        // if (dateParts.length !== 3) {
        //     console.error('Invalid birthdate format');
        //     return;
        // }

        // const day = parseInt(dateParts[0], 10);
        // const month = parseInt(dateParts[1], 10);
        // const year = parseInt(dateParts[2], 10);

        // if (isNaN(day) || isNaN(month) || isNaN(year)) {
        //     console.error('Invalid birthdate values');
        //     return;
        // }

        // if (month < 1 || month > 12) {
        //     console.error('Invalid month value');
        //     return;
        // }

        // const date = new Date(year, month - 1, day);

        // if (isNaN(date.getTime())) {
        //     console.error('Invalid date');
        //     return;
        // }

        await axios.post(PASSPORT_DATA, {
            phone_number: formattedPhoneNumber,
            visitor_oid: oid,
            firstname: name,
            lastname: patronymic,
            middlename: surname,
            birthdate: birthdate,
            email: email,
        })
            .then(response => navigate('/passport'))
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    navigate('/auth');
                } else {
                    setIsLoading(false)
                    console.log(error)
                }
            })
        dispatcher({ type: 'ADD_PHONE', payload: phone })
        dispatcher({ type: 'ADD_NAME', payload: name })

        localStorage.setItem('phone', formattedPhoneNumber)
        localStorage.setItem('name', name)
    }

    return (
        <>
            <div className={s.container + ' ' + s.contact_page} style={{ height: '100%', paddingBottom: '24px' }}>
                <div className={s.title}>
                    <h1>КОНТАКТНЫЕ ДАННЫЕ</h1>
                </div>
                <div className={s.content}>
                    <form method='post' onSubmit={e => {
                        e.preventDefault()
                        handleAddContactInfo()
                    }}>
                        <div className={s.input_field}>
                            <label htmlFor="name">Имя</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder=""
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="surname">Фамилия</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                placeholder=""
                                onChange={(e) => setSurname(e.target.value)}
                                value={surname}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="patronymic">Отчество</label>
                            <input
                                type="text"
                                id="patronymic"
                                name="patronymic"
                                placeholder=""
                                onChange={(e) => setPatronymic(e.target.value)}
                                value={patronymic}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="phone">Номер телефона</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder=""
                                onChange={(e) => handleChangePhone(e)}
                                value={phone}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="name">Дата рождения</label>
                            <input
                                type="date"
                                id="birthdate"
                                name="birthdate"
                                placeholder="DD.MM.YYYY"
                                onChange={(e) => handleBirthdateChange(e)}
                                value={birthdate}
                            // maxLength={10}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="email">Электронная почта</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder=""
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                        <div className={s.checkbox_field}>
                            <input type="checkbox" name='know' id='know' onChange={() => setKnow(!know)} checked={know} />
                            <label htmlFor="know">Я ознакомлен и согласен <span>со следующим</span></label>
                        </div>
                        <div className={s.checkbox_field}>
                            <input type="checkbox" name='agreement' id='agreement' onChange={() => setAgreement(!agreement)} checked={agreement} />
                            <label htmlFor="agreement">Даю согласие на <span>рекуррентные списания, хранение данных владельца банковской карты.</span> <p>Стоимость услуги - 1 996,00 RUB.</p></label>
                        </div>

                        <div className={s.form_btn} style={{ paddingTop: '8px' }}>
                            {
                                isLoading
                                    ? <div className="loading" />
                                    : <input disabled={!name || !surname || !patronymic || phone.length < 18 || !birthdate || !email || !know || !agreement} type="submit" value="Продолжить" />
                            }
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default ContactInfo