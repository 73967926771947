import React from 'react'
import s from './style.module.scss'
import Propositions from '../components/Proposirions/Propositions'

import Footer from '../components/Footer/Footer';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface Data {
    oid: string,
    id: number
    logo: string
    name: string
    link: string
    countries: [],
    conversion_rate: number,
    description: string,
    show: boolean,
    place: null,
    epc: number,
    epl: number,
    query_param_name: string
}

const PropositionsPage: React.FC = () => {
    const GET_OFFERS = process.env.REACT_APP_OFFER_BY_OID as string
    const oidStore = useSelector((state: RootState) => state.oid);
    const user = useSelector((state: RootState) => state.user) as { name: string };

    const { data, isLoading, isError }: { data: Data[] | undefined, isLoading: boolean, isError: boolean } = useQuery(
        'propositions-data',
        async () => {
            const response = await axios.get<Data[]>(GET_OFFERS, {
                params: {
                    visitor_oid: oidStore ? oidStore : localStorage.getItem('oid'),
                }
            });
            const sortedData = response.data.sort((a, b) => b.epc - a.epc);
            return sortedData
        },
        {
            refetchOnWindowFocus: false
        }
    )

    const [showMore, setShowMore] = React.useState<boolean>(false)

    return (
        <>
            {
                isLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><div className='loading' /></div> :

                    isError ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Произошла ошибка во время загрузки, попробуйте позже...</div> :
                        <>
                            <div className={s.container + ' ' + s.propositions} style={{ height: '100%', paddingBottom: '24px' }}>
                                <div className={s.title}>
                                    <h1>{user.name || localStorage.getItem('name')}, ВАМ ОДОБРЕМ ЗАЕМ В ЭТИХ КОМПАНИЯХ!</h1>
                                </div>
                                <div className={s.container_banks + ' ' + s.container_banks_proposition}>
                                    {
                                        !data || data.length === 0 ? (
                                            <div style={{ textAlign: 'center' }}>Подходящих данных не найдено.</div>
                                        ) : (
                                            !showMore ? (
                                                data.slice(0, 8).map((item, key) => (
                                                    <Propositions key={key} money={item.name} img={item.logo} title={item.description} link={`${item.link}?${item.query_param_name}=${oidStore ? oidStore : localStorage.getItem('oid')}`} />
                                                ))
                                            ) : (
                                                data.map((item, key) => (
                                                    <Propositions key={key} money={item.name} img={item.logo} title={item.description} link={`${item.link}?${item.query_param_name}=${oidStore ? oidStore : localStorage.getItem('oid')}`} />
                                                ))
                                            )
                                        )
                                    }
                                </div>
                                {
                                    data && data.length > 8 && !showMore &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button onClick={() => setShowMore(!showMore)} style={{
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                            fontFamily: 'var(--font-family)',
                                            padding: '18px 44px',
                                            color: 'var(--primary-text-color)',
                                            backgroundColor: 'var(--gray)',
                                            borderRadius: '100px',
                                            border: 'none'
                                        }}>Еще варианты</button>
                                    </div>
                                }
                            </div>
                            <Footer />
                        </>
            }
        </>
    )
}

export default PropositionsPage