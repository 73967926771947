import { combineReducers, Reducer } from 'redux';
import OIDReducer from './OIDReduser';
import UserReduser from './UserReduser';
import exp from 'constants';

const rootReducer = combineReducers({
    oid: OIDReducer,
    user: UserReduser
}) as Reducer<{ oid: string | undefined, user: object | undefined }, any>;

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;