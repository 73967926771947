// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__wfhuK {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: calc(50% - 4px);
  height: 120px;
  border-radius: var(--border-radius-continer);
  background-color: var(--primary-text-color);
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.style_card__wfhuK .style_content__A8rdM h1 {
  font-size: 14px;
}

@media screen and (min-width: 1200px) {
  .style_card__wfhuK {
    margin-top: 80px;
    padding: 56px;
    width: auto;
    max-width: calc(25% - 8px);
    background-color: var(--light-gray);
  }
}
@media screen and (min-width: 1700px) {
  .style_card__wfhuK {
    margin-top: 80px;
    padding: 56px;
    width: 100%;
    max-width: calc(25% - 8px);
    background-color: var(--light-gray);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Card/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,sBAAA;EACA,aAAA;EACA,4CAAA;EACA,2CAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAEQ;EACI,eAAA;AAAZ;;AAKA;EACI;IACI,gBAAA;IACA,aAAA;IACA,WAAA;IACA,0BAAA;IACA,mCAAA;EAFN;AACF;AAIA;EACI;IACI,gBAAA;IACA,aAAA;IACA,WAAA;IACA,0BAAA;IACA,mCAAA;EAFN;AACF","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n    width: calc((50% - 4px)) ;\n    height: 120px;\n    border-radius: var(--border-radius-continer);\n    background-color: var(--primary-text-color);\n    font-weight: 500;\n    font-size: 14px;\n    text-align: center;\n\n    .content {\n        h1 {\n            font-size: 14px;\n        }\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    .card {\n        margin-top: 80px;\n        padding: 56px;\n        width: auto;\n        max-width: calc((100% / 4 - 8px)); ;\n        background-color: var(--light-gray);\n    }\n}\n@media screen and (min-width: 1700px) {\n    .card {\n        margin-top: 80px;\n        padding: 56px;\n        width: 100%;\n        max-width: calc((100% / 4 - 8px)); ;\n        background-color: var(--light-gray);\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style_card__wfhuK`,
	"content": `style_content__A8rdM`
};
export default ___CSS_LOADER_EXPORT___;
