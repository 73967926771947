import React, { useState } from 'react'
import s from './style.module.scss'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const PassportDataPage: React.FC = () => {
    const [gender, setGender] = useState<string>('');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);
    };

    const [passSeries, setPassSeries] = useState<string>('');
    const [passNumber, setPassNumber] = useState<string>('');
    const [codePosition, setCodePosition] = useState<string>('');
    const [dateOfIssue, setDateOfIssue] = useState<string>('');
    const [birthdayPlace, setBirthPlace,] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false)

    const userState = useSelector((state: any) => state.user)
    const navigate = useNavigate()

    const PASSPORT_DATA = process.env.REACT_APP_PASSPORT_DATA as string


    const handleSubmitPassportData = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true)

        await axios.patch(PASSPORT_DATA, {
            number_phone: localStorage.getItem('phone'),
            birthplace: birthdayPlace,
            gender,
            passport_code: `${passSeries}; ${passNumber}; ${codePosition}; ${dateOfIssue}`,
        }, {
            withCredentials: true
        })
            .then(res => navigate('/address'))
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Ошибка в добавлении адресса")
            });
    };


    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDateOfIssue(e.target.value);
    };

    return (
        <>
            <div className={s.container + ' ' + s.container_contact}>
                <div className={s.title}>
                    <h1>ПАСПОРТНЫЕ ДАННЫЕ</h1>
                </div>
                <div className={s.content}>
                    <form method='post' onSubmit={e => handleSubmitPassportData(e)} >
                        <div className={s.input_field}>
                            <label htmlFor="passportSeries">Серия паспорта</label>
                            <input
                                type="number"
                                id="passportSeries"
                                name="passportSeries"
                                placeholder=""
                                value={passSeries}
                                onChange={(e) => {
                                    if (e.target.value.length > 4) {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                    setPassSeries(e.target.value);
                                }}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="passportNumber">Номер паспорта</label>
                            <input
                                type="number"
                                id="passportNumber"
                                name="passportNumber"
                                placeholder=""
                                value={passNumber}
                                onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                    setPassNumber(e.target.value);
                                }}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="codePosition">Код подразделения</label>
                            <input
                                type="number"
                                id="codePosition"
                                name="codePosition"
                                placeholder=""
                                value={codePosition}
                                onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                        e.target.value = e.target.value.slice(0, 6);
                                    }
                                    setCodePosition(e.target.value);
                                }}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="dateOfIssue">Дата выдачи паспорта</label>
                            <input
                                type="date"
                                id="dateOfIssue"
                                name="dateOfIssue"
                                placeholder="DD.MM.YYYY"
                                value={dateOfIssue}
                                onChange={(e) => handleDateChange(e)}
                            />
                        </div>
                        <div className={s.input_field}>
                            <label htmlFor="birthPlace">Место рождения</label>
                            <input
                                type="text"
                                id="birthPlace"
                                name="birthPlace"
                                placeholder=""
                                value={birthdayPlace}
                                onChange={(e) => setBirthPlace(e.target.value)}
                            />
                        </div>
                        <div className={s.checkbox_container}>
                            <div className={s.checkbox_field}>
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={gender === 'male'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="male">Мужской</label>
                            </div>
                            <div className={s.checkbox_field}>
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={gender === 'female'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="female">Женский</label>
                            </div>
                        </div>
                        <div className={s.form_btn}>
                            {
                                isLoading
                                    ? <div className="loading" />
                                    : <input disabled={!gender || passSeries.length < 4 || passNumber.length < 6 || !codePosition || !dateOfIssue || !birthdayPlace} type="submit" value="Продолжить" />
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PassportDataPage